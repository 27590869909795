<template>
	<div>
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="组件名称">
				<Input placeholder="请输入组件名称" v-model="searchParams.name" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="所属标签">
				<Input placeholder="请输入标签名称" v-model="searchParams.tag" />
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button @click="handleAdd">新增</Button>
			<Button @click="handleDownLoadTemplate">下载导入模板</Button>
			<Upload
				ref="uploadStaff"
				:headers="{ token: $store.state.user.token }"
				:show-upload-list="false"
				accept=".xlsx"
				:before-upload="checkBeforeUpload"
				:on-success="onSuccess"
				:on-error="onError"
				:data="extraData"
				:action="`${$baseUrl}/common/form-component/excel-import`"
				style="display: inline-block"
			>
				<Button :loading="uploadLoading">导入</Button>
			</Upload>
			<Button @click="handleExportExcel">导出数据</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<Add
			:visible="addVisible"
			:updateId="updateId"
			:apiList="apiList"
			:roleList="roleList"
			@onCancel="addVisible = false"
			@onOk="handleOk"
		>
		</Add>
	</div>
</template>

<script>
import { mapState } from "vuex"
import api from "@/api/setting/formItem"
import configApi from "@/api/setting/process"
import { formDataToMD5, paramsStringify } from "@/utils/util"
import Add from "./Add.vue"

const { getFormItemListByPage, enableDisabled, getRequestList } = api
const { apiGetRoleList } = configApi

export default {
	name: "formItem",
	components: {
		Add
	},
	data() {
		return {
			loading: false,
			apiList: [],
			roleList: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			columns: [
				{
					title: "组件名称",
					key: "name",
					minWidth: 200
				},
				{
					title: "组件code",
					minWidth: 150,
					align: "center",
					render: (h, { row }) => h("span", {}, JSON.parse(row.data).code)
				},
				{
					title: "所属标签",
					key: "tag",
					align: "center",
					minWidth: 100
				},
				{
					title: "组件类型",
					key: "type",
					minWidth: 120,
					align: "center",
					render: (h, { row }) => h("span", {}, JSON.parse(row.data).label)
				},
				{
					title: "状态",
					key: "publish",
					minWidth: 100,
					align: "center",
					render: (h, params) =>
						h("span", {}, params.row.isShowDisable === 1 ? "已使用" : "未使用")
				},
				{
					title: "发布时间",
					key: "createdTime",
					minWidth: 100,
					align: "center"
				},
				{
					title: "操作",
					key: "action",
					width: 150,
					fixed: "right",
					renderButton: params => {
						const btnList = [
							{
								label: "修改",
								on: {
									click: this.handleShow
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: "您确认删除该表单配置项吗？"
								}
							}
						]
						if (params.row.isShowDisable === 1) {
							// 1，未在表单中使用过的组件，可以删除。
							btnList[1] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			],
			listData: [],
			searchParams: {
				name: "",
				tag: ""
			},
			oldSearchParams: {},
			addVisible: false,
			updateId: "",
			extraData: {
				md5: ""
			},
			uploadLoading: false
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 100
		})
		// ...mapState('permission', ['systemActionPermissions']),
	},
	created() {
		this.initList()
		this.getRoleList()
		this.getRequestList()
	},
	methods: {
		async initList() {
			this.loading = true
			const res = await getFormItemListByPage({
				pageNum: this.page.current,
				pageSize: this.page.pageSize,
				del: 0,
				...this.oldSearchParams
			})
			if (res) {
				this.listData = res.data.list
				this.page.total = res.data.total
			}
			this.loading = false
		},
		// 获取角色列表
		getRoleList() {
			this.$asyncDo(async () => {
				const res = await apiGetRoleList()
				if (res) {
					this.roleList = res.data || []
				}
			})
		},
		// 获取可选择的请求接口列表
		async getRequestList() {
			const res = await getRequestList()
			if (res) {
				this.apiList = res.data || []
			}
		},
		handleAdd() {
			this.updateId = ""
			this.addVisible = true
		},
		handleShow({ row }) {
			this.updateId = row.id
			this.addVisible = true
		},
		handleOk() {
			this.addVisible = false
			this.searchHandle()
		},
		// 重置
		reset() {
			this.searchParams = {
				name: "",
				tag: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 删除
		async handleSwitchState({ row }) {
			this.loading = true
			let res = false
			res = await enableDisabled(row.id)
			if (res) {
				this.$Message.success("操作成功！")
				this.searchHandle()
			}
			this.loading = false
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 下载导入模板
		handleDownLoadTemplate() {
			window.open(
				`${this.$baseUrl}/common/form-component/import-template-download?token=${this.$store.state.user.token}`
			)
		},
		// 上传文件
		checkBeforeUpload(file) {
			this.uploadLoading = true
			formDataToMD5(file, md5Str => {
				this.extraData.md5 = md5Str
				// 获取md5后进行文件上传
				this.$refs.uploadStaff.post(file)
			})
			return false
		},
		// 文件上传成功后
		onSuccess(res) {
			if (res.code !== 0) {
				this.$Message.error(res.message)
			} else {
				this.$Message.success("上传成功!")
				this.initList()
			}
			this.uploadLoading = false
		},
		onError(error) {
			this.$Message.error(error)
			this.uploadLoading = false
		},
		handleExportExcel() {
			window.open(
				`${this.$baseUrl}/common/form-component/excel-export?${paramsStringify({
					token: this.$store.state.user.token,
					...this.oldSearchParams,
					del: 0
				})}`
			)
		}
	}
}
</script>

<style scoped></style>
