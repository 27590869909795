<template>
	<Modal
		class="project-process-modal"
		:value="visible"
		width="1000"
		:fullscreen="fullscreen"
		:scrollable="true"
		:mask-closable="false"
		:footer-hide="true"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="modal-header">
			<h2>流程列表</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<CtmsAction>
			<Button type="primary" @click="handleStartConfirm" v-if="showStartBtn"
				>启动流程</Button
			>
			<Button
				v-if="
					$store.state.user.info.id === '1' || $store.state.user.info.id === '2'
				"
				@click="handleToDesign"
				>跳转到流程设计页面</Button
			>
		</CtmsAction>
		<CtmsDataGrid
			:row-class-name="rowClassName"
			:height="gridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<Approval
			:visible="approvalVisible"
			:projectId="projectId"
			:taskId="taskId"
			@onCancel="approvalVisible = false"
			@onOk="handleOk"
			:type="approvalType"
			:toDoId="toDoId"
			:procInstId="procInstId"
			:procInstName="procInstName"
		></Approval>
		<StartConfirm
			:visible="startVisible"
			:list-data="listData"
			@onOk="handleStartProcess"
			@onCancel="startVisible = false"
		>
		</StartConfirm>
	</Modal>
</template>

<script>
import api from "@/api/project/process"
import processApi from "@/api/setting/process"
import projApi from "@/api/project/project"
import { mapState } from "vuex"
import Approval from "@approval"
import StartConfirm from "./StartConfirm.vue"

const { apiGetProcessList, apiStartProcess, apiModelRedeploy, apiDelProcess } =
	api
const { apiGet } = processApi

export default {
	name: "ProcessingList",
	components: {
		Approval,
		StartConfirm
	},
	props: ["visible", "modelId", "category"],
	data() {
		return {
			approvalType: "view",
			taskId: "",
			toDoId: "",
			loading: false,
			fullscreen: false,
			listData: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			columns: [
				{
					title: "流程名称",
					key: "name",
					minWidth: 150
				},
				{
					title: "启动用户",
					key: "startUserName"
				},
				{
					title: "启动时间",
					key: "startTime"
				},
				{
					title: "状态",
					key: "status",
					width: 100
				},
				{
					title: "操作",
					key: "action",
					minWidth: 170,
					renderButton: params => {
						const actionList = [
							{
								// 0
								label: params.row.todoAuth ? "进入流程" : "查看",
								on: {
									click: this.handleApporovalViewShow
								}
							},
							{
								// 1
								label: "导出批注",
								on: {
									click: this.handleExportRemark
								}
							},
							{
								// 2
								label:
									this.env === "shetyy" && this.category === "START"
										? "导出"
										: "导出审批意见",
								on: {
									click: this.export
								},
								confirm: {
									title: `您确认导出该流程内的${this.env === "shetyy" && this.category === "START" ? "立项清单" : "所有审批意见"}吗？`
								}
							},
							{
								// 3
								label: "重新部署",
								on: {
									click: this.handleRedeploy
								}
							},
							{
								// 4
								label: "删除",
								on: {
									click: this.handleDelete
								},
								confirm: {
									title: "是否删除此流程？"
								}
							}
						]
						// 用户id为 1 或者 2 ,代表是admin和super 管理员身份
						if (
							this.$store.state.user.info.id !== "1" &&
							this.$store.state.user.info.id !== "2"
						) {
							actionList[3] = null
							actionList[4] = null
						}
						if (
							this.env !== "shenzhenrenmin" ||
							this.projectActionPermissions.indexOf(
								"btn_project_export_annotation"
							) === -1
						) {
							actionList[1] = null
						}
						if (
							this.env === "shetyy" &&
							this.category === "START" &&
							params.row.status === "进行中"
						) {
							actionList[2] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			viewVisible: false,
			historyVisible: false,
			processId: "",
			approvalVisible: false,
			startVisible: false,
			showStartBtn: true,
			procInstId: "",
			procInstName: ""
		}
	},
	watch: {
		visible() {
			if (this.visible && this.modelId) {
				this.initList()
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 200
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 150
				: this.dataGridHeight
		},
		env() {
			return this.$store.state.environment
		},
		projectId() {
			return this.$route.params.projectId
		},
		...mapState("permission", ["projectActionPermissions"])
	},
	methods: {
		// 初始化列表
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetProcessList({
					projectId: this.projectId,
					modelId: this.modelId,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list.map(item => {
						Object.assign(item, {
							status: item.endTime ? "已完成" : "进行中"
						})
						return item
					})
					this.page.total = res.data.total
				}
				const res2 = await apiGet({ id: this.modelId })
				if (res2 && res2.data) {
					this.showStartBtn = res2.data.startBtnShow
				}
				this.loading = false
			})
		},
		// 查看流程 / 流程审批
		handleApporovalViewShow({ row }) {
			console.log(row)
			if (row.todoAuth) {
				this.approvalType = "toDo"
				this.toDoId = row.latestTodoId
			} else {
				this.approvalType = "view"
				this.toDoId = ""
			}
			this.procInstId = row.id
			this.procInstName = row.name
			this.taskId = row.taskId
			this.$nextTick(() => {
				this.approvalVisible = true
			})
		},
		// 审批提交成功
		handleOk() {
			this.approvalVisible = false
			this.$store.dispatch(
				"project/getFollowCheck",
				this.$route.params.projectId
			) // 获取跟踪审查信息
			this.$store.dispatch("project/getExistCode", this.projectId) // 获取编号修改信息
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 导出批注
		handleExportRemark({ row }) {
			const url = `${this.$baseUrl}/flowable/process/process-annotation-export/${this.projectId}?procInstId=${row.id}&token=${this.$store.state.user.token}`
			window.open(url)
		},
		// 导出该流程内的所有审批意见
		// 儿童医院，导出内容由三部分组成：项目信息、此流程流程审批记录、此流程已上传文件名称
		export({ row }) {
			let url = ""
			// 儿童医院环境，【流程管理】，只有立项阶段下的流程才展示导出按钮,
			// 导出内容包含三部分：项目信息，此流程审批记录，此流程已上传文件名称
			if (this.env === "shetyy" && this.category === "START") {
				url = `${this.$baseUrl}/flowable/process/process-data-export/${this.projectId}?procInstId=${row.id}&token=${this.$store.state.user.token}`
			} else {
				url = `${this.$baseUrl}/flowable/process/${row.id}/audit-opinion-export?token=${this.$store.state.user.token}`
			}
			window.open(url)
		},
		// 删除流程
		async handleDelete({ row }) {
			this.loading = true
			const res = await apiDelProcess({
				projectId: this.projectId,
				procInstId: row.id,
				reason: "删除流程"
			})
			if (res) {
				this.$Message.success("操作成功!")
				this.page.current = 1
				this.$nextTick(() => {
					this.initList()
				})
			}
			this.loading = false
		},
		// 重新部署流程
		handleRedeploy({ row }) {
			this.$Modal.confirm({
				title: "重新部署流程",
				content:
					"您确认重新部署此流程吗？（提示：请先到流程设计页面修改此流程，再进行部署。）",
				onOk: async () => {
					const res = await apiModelRedeploy({
						processDefinitionId: row.processDefinitionId,
						processDeploymentId: row.processDeploymentId,
						modelId: row.businessKey,
						procInstId: row.id
					})
					if (res) {
						this.$Message.success("流程重新部署成功")
					}
				}
			})
		},
		// 跳转到流程设计页面
		handleToDesign() {
			sessionStorage.setItem("searchParams", "")
			this.$router.push({
				name: "settingProcess",
				query: {
					projectId: this.projectId
				}
			})
		},
		// 关闭
		onCancel() {
			// this.$emit('onCancel');
			this.$emit("startOk")
			this.fullscreen = false
		},
		// 启动流程确认
		handleStartConfirm() {
			this.startVisible = true
		},
		// 启动流程
		handleStartProcess(sendSysMessage) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiStartProcess({
					projectId: this.projectId,
					modelId: this.modelId,
					sendSysMessage
				})
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
					// this.$emit('startOk');
				} else {
					this.loading = false
				}
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 根据状态，显示表格背景颜色
		rowClassName(row) {
			if (!row.endTime) {
				return "processing-row"
			}
			return ""
		}
	}
}
</script>

<style lang="less">
.ivu-table .processing-row td {
	background-color: #dcf5ff;
	color: #3a97f6;
	a {
		color: #3a97f6;
	}
}
.project-process-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
</style>
