<template>
	<Modal
		class="design-table-modal"
		:value="visible"
		:fullscreen="fullscreen"
		:mask-closable="false"
		:styles="{ top: fullscreen ? 0 : '20px' }"
		@on-cancel="onCancel"
		width="1150"
	>
		<div slot="header" class="add-pro-modal-header">
			<h2>表格设计</h2>
			<a href="javascript:;" class="fullscreen" @click="handleFullScreen">
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<Row :gutter="32">
			<Col
				span="6"
				:style="`height:${conHeight}px`"
				class="add-col-wrapper con"
			>
				<draggable
					v-model="columns"
					@end="onMovedEnd"
					:options="{ group: 'dataitem', animation: 150 }"
				>
					<div v-for="(col, idx) in columns" :key="idx" class="colList">
						<Input v-model="col.title" placeholder="请输入列名">
							<Button slot="append" icon="md-remove" @click="delCol(idx)">
							</Button>
						</Input>
					</div>
				</draggable>
				<Button icon="ios-add" type="primary" @click="addCol">添加列 </Button>
			</Col>
			<Col span="18" class="con" :style="`height:${conHeight}px`">
				<div class="table-template">
					<div class="div-table">
						<table>
							<tr>
								<th v-for="(col, index) in columns" :key="index" tabindex="0">
									{{ col.title }}
								</th>
							</tr>
							<tr v-for="(row, index) in rows" :key="index">
								<td
									v-for="(col, colIndex) in columns"
									:key="colIndex"
									tabindex="0"
								>
									<i
										class="ivu-icon ivu-icon-md-remove-circle row-delete"
										v-if="colIndex === 0"
										@click.stop="delRow(index)"
									></i>
									<Input
										v-if="row[col.key].type === 'Text'"
										v-model="row[col.key].value"
									/>
								</td>
							</tr>
						</table>
					</div>
					<div class="div-footer" v-if="columns.length">
						<Button icon="ios-add" type="primary" @click="addRow"
							>添加行</Button
						>
					</div>
				</div>
			</Col>
		</Row>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk">确定 </Button>
		</div>
	</Modal>
</template>
<script>
import draggable from "vuedraggable"
import { mapState } from "vuex"
import "@/components/BusinessComponents/FormDesign/TableComp.less"

export default {
	props: ["visible", "tableObj"],
	components: {
		draggable
	},
	data() {
		return {
			fullscreen: false,
			columns: [],
			rows: []
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.columns = []
				this.rows = []
				this.columns = [...this.tableObj.columns]
				this.rows = [...this.tableObj.rows]
			}
		}
	},
	computed: {
		...mapState({
			contentHeight: state => state.contentHeight - 40
		}),
		conHeight() {
			return this.fullscreen
				? document.body.clientHeight - 145
				: this.contentHeight
		}
	},
	methods: {
		handleFullScreen() {
			// 全屏查看
			this.fullscreen = !this.fullscreen
		},
		onCancel() {
			this.$emit("onCancel")
		},
		// 添加列
		addCol() {
			const col = {
				title: "列名",
				key: this.getRandomId()
			}
			this.columns.push(col)
			this.rows = this.rows.map(row => {
				const newRow = { ...row }
				newRow[col.key] = {
					value: "",
					type: "Text"
				}
				return newRow
			})
		},
		// 删除列
		delCol(idx) {
			if (this.columns.length === 1) {
				this.$Message.error("一列情况下不可删除列！")
				return false
			}
			this.columns.splice(idx, 1)
			return true
		},
		// 添加行
		addRow() {
			const row = {
				isDefaultRow: true
			}
			this.columns.forEach(col => {
				row[col.key] = {
					value: "",
					type: "Text"
				}
			})
			this.rows.push(row)
		},
		// 删除行
		delRow(index) {
			this.rows.splice(index, 1)
		},
		// 排序完成
		onMovedEnd() {
			this.columns = [...this.columns]
		},
		getRandomId() {
			return new Date().getTime().toString(32).toUpperCase()
		},
		onOk() {
			const tableObj = {
				columns: [...this.columns],
				rows: [...this.rows]
			}
			this.$emit("onOk", tableObj)
			this.onCancel()
		}
	}
}
</script>
<style lang="less">
.design-table-modal {
	.add-pro-modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.add-org-btn {
			position: absolute;
			right: 100px;
			top: 11px;
			padding: 2px 15px;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
	.con {
		overflow: auto;
	}
	.colList {
		margin-bottom: 10px;
	}
	.add-col-wrapper {
		border-right: 1px solid #ddd;
		min-height: 300px;
	}
}
</style>
